import React, { useState } from "react";
import * as THREE from "three";
import { Canvas, useLoader } from "react-three-fiber";
import { OrbitControls, Stats } from "drei";
import ClickObjectInfo from "./components/utility/ClickObjectInfo";
import { Project } from "./Project";
import Hud from "./components/utility/hud";
import { isLoaded as textIsLoaded } from "./components/XText";
import useAnimationFrame from "./hooks/useAnimationFrame";
import spinner from "./spinner.gif";

const HUD_STATS = true;
const ORBIT_CONTROLS = false;
const CLICK_INFO = false;

export default function App({ data }) {
  const [appCanStart, setAppCanStart] = useState(false);

  data.assets.textures.uvGrid = useLoader(
    THREE.TextureLoader,
    "./textures/uvGrid.jpg"
  );
  data.assets.textures.testArtArray = useLoader(
    THREE.TextureLoader,
    data.user.testArt
  );
  data.assets.textures.gamerPic = useLoader(
    THREE.TextureLoader,
    data.user.gamerpic
  );

  useAnimationFrame(() => {
    if (!appCanStart) {
      if (textIsLoaded) {
        setAppCanStart(true);
      }
    }
  });

  return (
    <>
      {!appCanStart && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img src={spinner} />
        </div>
      )}
      {appCanStart && (
        <Canvas
          concurrent
          style={{ position: "absolute" }}
          gl={{
            antialias: true,
            precision: "highp",
            powerPreference: "high-performance",
          }}
          pixelRatio={window.devicePixelRatio}
          onCreated={({ gl }) => {
            gl.setClearColor("#000000");
          }}
        >
          <Project data={data} hudEnabled={HUD_STATS} />
          {CLICK_INFO && <ClickObjectInfo />}
          {HUD_STATS && <Stats />}
          {ORBIT_CONTROLS && <OrbitControls />}
        </Canvas>
      )}

      {HUD_STATS && <Hud />}
    </>
  );
}
