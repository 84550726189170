import React, { useRef } from "react";
import { useThree, useFrame } from "react-three-fiber";
import OutlinePlane from "../components/outline/OutlinePlane";
import XText from "../components/XText";
import { Colors } from "../Colors";

export default function Scene010_Welcome({ data, sceneTime }) {
  const state = useThree();
  const group = useRef();
  const mesh = useRef();

  useFrame(() => {
    const { isActive, time, progress } = sceneTime;

    group.current.visible = isActive;
    if (!isActive) {
      return;
    }

    state.camera.position.set(0, 0, 5);
    state.camera.lookAt(state.scene.position);
  });

  return (
    <group ref={group}>
      <ambientLight intensity={0.75} />
      <OutlinePlane width={8} height={2} segments={3} />
      <XText position={[-2, 0.25, 0]} color={Colors.white} anchorX="left">
        WELCOME
      </XText>
      <XText position={[-2, -0.25, 0]} color={Colors.green} anchorX="left">
        {data.script.card1}
      </XText>
      <mesh position={[-3, 0, 0]}>
        <circleBufferGeometry attach="geometry" args={[0.55, 50]} />
        <meshBasicMaterial
          attach="material"
          map={data.assets.textures.gamerPic}
        />
      </mesh>
    </group>
  );
}
