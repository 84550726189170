import React, { useState, useRef, useCallback, useEffect } from "react";
import { useFrame } from "react-three-fiber";

import Scene010_Welcome from "./scenes/Scene010_Welcome";
import Scene020_TotalGames from "./scenes/Scene020_TotalGames";
import Scene030_Years from "./scenes/Scene030_Years";
import Scene040_Achievements from "./scenes/Scene040_Achievements";
import Scene050_NotAlone from "./scenes/Scene050_NotAlone";
import Scene060_Friends from "./scenes/Scene060_Friends";
import Scene070_Parties from "./scenes/Scene070_Parties";
import Scene080_BestAhead from "./scenes/Scene080_BestAhead";

const TRANSPORT_CONTROL = true;
const FPS = 60.0; // Target FPS
const ONE_FRAME = 1.0 / FPS;
const LOOP = true;

// CLIP SEQUENCE TIMELINE
// =======================

const sceneComponents = {
  Scene010_Welcome,
  Scene020_TotalGames,
  Scene030_Years,
  Scene040_Achievements,
  Scene050_NotAlone,
  Scene060_Friends,
  Scene070_Parties,
  Scene080_BestAhead,
};

export const timeline = [
  ["Scene010_Welcome", 2],
  ["Scene020_TotalGames", 4],
  ["Scene030_Years", 7],
  ["Scene040_Achievements", 2],
  ["Scene050_NotAlone", 2],
  ["Scene060_Friends", 2],
  ["Scene070_Parties", 2],
  ["Scene080_BestAhead", 2],
];

// -----------------------

const createClip = ({ scene, start, end }) => {
  let sceneTime = {
    time: 0,
    progress: 0,
    isActive: false,
  };

  return {
    scene,
    start,
    end,
    sceneTime,
  };
};

const createClipSequence = (timeline) => {
  let sequencedClips = [];
  let prevEnd = 0;

  for (let i = 0; i < timeline.length; i++) {
    const [scene, duration] = timeline[i];

    const start = prevEnd;
    const end = start + duration;

    const clip = createClip({
      scene,
      start,
      end,
    });

    sequencedClips.push(clip);
    prevEnd = end;
  }

  return sequencedClips;
};

// TODO: Add support for overlapping clips
export const clipSequence = createClipSequence(timeline);

const getMinTime = (timeMap) => {
  const startTimes = timeMap.map((scene) => scene.start);
  return startTimes.reduce((minTime, t) => Math.min(minTime, t));
};

const getMaxTime = (timeMap) => {
  const endTimes = timeMap.map((scene) => scene.end);

  return endTimes.reduce((endTime, t) => Math.max(endTime, t));
};

let previewMinTime = getMinTime(clipSequence);
let previewMaxTime = getMaxTime(clipSequence);

const isClipActive = (clip, projectTime) => {
  // console.log({ clip });
  // console.log(projectTime);
  return clip.start <= projectTime.time && projectTime.time < clip.end;
};

const getCurrentClipData = (clips, projectTime) => {
  let clipData = {
    clip: undefined,
    id: -1,
    number: 0,
    count: clips.length,
  };

  for (let i = 0; i < clips.length; i++) {
    let clip = clips[i];

    if (isClipActive(clip, projectTime)) {
      clipData.clip = clip;
      clipData.id = i;
      clipData.number = i + 1;
      break;
    }
  }

  return clipData;
};

export const Project = ({ data, hudEnabled }) => {
  const [clips] = useState(clipSequence);

  const projectTime = { time: 0 };
  const playSpeedRef = useRef(1);

  const onDown = useCallback((e) => {
    const keyPressed = e.keyCode;

    const KEY_PLAY_PAUSE = 32; // Spacebar
    const KEY_SHUTTLE_BACK = 74; // J
    const KEY_SHUTTLE_PLAY_PAUSE = 75; // K
    const KEY_SHUTTLE_FORWARD = 76; // L
    const KEY_FRAME_FORWARD = 39; // -> Arrow Right
    const KEY_FRAME_BACK = 37; // <- Arrow Left

    const KEY_SCENES = [
      49, // 1
      50, // 2
      51, // 3
      52, // 4
      53, // 5
      54, // 6
      55, // 7
      56, // 8
      57, // 9
      48, // 10
    ];

    const KEY_PREVIEW_ALL = 192; // `

    if ([KEY_PLAY_PAUSE].includes(keyPressed)) {
      playSpeedRef.current !== 0
        ? (playSpeedRef.current = 0)
        : (playSpeedRef.current = 1);
    } else if (keyPressed === KEY_SHUTTLE_PLAY_PAUSE) {
      playSpeedRef.current = 0;
    } else if (keyPressed === KEY_SHUTTLE_BACK) {
      playSpeedRef.current = Math.min(playSpeedRef.current - 0.5, -0.5);
    } else if (keyPressed === KEY_SHUTTLE_FORWARD) {
      playSpeedRef.current = Math.max(playSpeedRef.current + 0.5, 0.5);
    } else if (keyPressed === KEY_FRAME_BACK) {
      playSpeedRef.current = 0;
      projectTime.time -= ONE_FRAME;
    } else if (keyPressed === KEY_FRAME_FORWARD) {
      playSpeedRef.current = 0;
      projectTime.time += ONE_FRAME;
    } else if (KEY_SCENES.includes(keyPressed)) {
      // Switch Scene
      let sceneId = KEY_SCENES.indexOf(keyPressed);
      if (sceneId >= clipSequence.length) {
        sceneId = clipSequence.length - 1;
      }

      const scene = clipSequence[sceneId];
      previewMinTime = scene.start;
      previewMaxTime = scene.end;
      playSpeedRef.current = 1;
      projectTime.time = previewMinTime;
    } else if (keyPressed === KEY_PREVIEW_ALL) {
      previewMinTime = getMinTime(clipSequence);
      previewMaxTime = getMaxTime(clipSequence);
      playSpeedRef.current = 1;
      projectTime.time = previewMinTime;
    }
  }, []);

  useFrame((state, delta) => {
    let t = projectTime.time + delta * playSpeedRef.current;

    if (t < previewMinTime) {
      if (LOOP) {
        t = previewMaxTime;
      } else {
        playSpeedRef.current = 0.0;
        t = previewMinTime;
      }
    }

    if (t > previewMaxTime) {
      if (LOOP) {
        t = previewMinTime;
      } else {
        playSpeedRef.current = 0.0;
        t = previewMaxTime;
      }
    }

    // Update project time
    projectTime.time = t;

    // Update each clip's sceneTime object's properties
    for (let i = 0; i < clips.length; i++) {
      const clip = clips[i];

      const time = t - clip.start;
      const progress = time / (clip.end - clip.start);

      clip.sceneTime.time = time;
      clip.sceneTime.progress = progress;
      clip.sceneTime.isActive = isClipActive(clip, projectTime);
    }

    // Manual render - TODO: Why is this necessary when FPS stat overlay is disabled?
    if (!hudEnabled) {
      state.gl.render(state.scene, state.camera);
      return;
    }

    const { clip, number, count } = getCurrentClipData(clips, projectTime);
    if (clip == null) return;

    Object.assign(HudData, {
      time: t,
      duration: getMaxTime(clipSequence),
      speed: playSpeedRef.current,
      scene: clip.scene,
      number: number,
      count: count,
    });
  });

  useEffect(() => {
    if (TRANSPORT_CONTROL) {
      window.addEventListener("keydown", onDown);
      return () => window.removeEventListener("keydown", onDown);
    }
  }, [onDown]);

  return (
    <>
      {clips.map((clip, index) => {
        const SceneComponent = sceneComponents[clip.scene];

        return (
          <SceneComponent key={index} data={data} sceneTime={clip.sceneTime} />
        );
      })}
    </>
  );
};

export const HudData = {
  time: 0,
  duration: 0,
  speed: 0,
  scene: "",
  sceneNumber: 1,
  sceneCount: 0,
};
