import * as THREE from "three";
import React, { useRef, useMemo, useEffect } from "react";
import { useThree, useFrame } from "react-three-fiber";
import * as ImageTools from "../tools/ImageTools";
import SimplexNoise from "simplex-noise";
import { easeInOutCubic, easeInQuad } from "js-easing-functions";

const imageSizeMax = 512;
const maxHours = 2190;
const spread = 10;
const maxScale = 3;
const minScale = 0.75;
const transitionTime = 1;
const noise = new SimplexNoise(5);

const cameraStartPos = new THREE.Vector3(10, 1, 10);
const cameraMidPos = new THREE.Vector3(0, 2, 20);
const cameraEndPos = new THREE.Vector3(0, 2, -30);

export default function Scene030_Years({ data, sceneTime }) {
  const state = useThree();
  const group = useRef();

  const artBoxes = useRef([]);
  const num = useRef(data.assets.textures.testArtArray.length);

  const makeBoxes = () => {
    data.assets.textures.testArtArray.forEach((texture, i) => {
      // resize textures
      texture.image = ImageTools.resizeImage(texture.image, imageSizeMax);

      let zPos = (num.current / 2 - i) * 0.2;
      let hours = Math.random() * maxHours;
      let sizeUp = Math.max(minScale, (hours / maxHours) * maxScale);

      const artBox = {
        initPos: new THREE.Vector3(0, 0, zPos),
        targetPos: new THREE.Vector3(
          noise.noise2D(i, 1) * spread,
          noise.noise2D(i, 10) * spread,
          zPos * 2
        ),
        initScale: new THREE.Vector3(1, 1, 1),
        targetScale: new THREE.Vector3(sizeUp, sizeUp, 1),
        timeMod: i * 0.025,
      };

      let finalX = artBox.targetPos.x < 0 ? -spread / 2 : spread / 2;
      artBox.finalPos = new THREE.Vector3(
        finalX,
        artBox.targetPos.y,
        zPos * 2 - i
      );
      artBox.finalRot = artBox.targetPos.x < 0 ? Math.PI / 2 : -Math.PI / 2;
      artBox.obj = new THREE.Mesh(
        new THREE.BoxBufferGeometry(1, 1, 0.025),
        new THREE.MeshBasicMaterial({ map: texture })
      );
      artBox.obj.position.copy(artBox.initPos);
      artBoxes.current.push(artBox);
    });
  };

  useMemo(() => {
    makeBoxes();
  });

  useEffect(() => {
    artBoxes.current.forEach((box) => {
      group.current.add(box.obj);
    });
  }, []);

  useFrame(() => {
    const { isActive, time, progress } = sceneTime;

    group.current.visible = isActive;
    if (!isActive) {
      return;
    }

    let t = progress * 10;

    if (t < 2) {
      artBoxes.current.forEach((box, i) => {
        box.obj.rotation.set(0, 0, 0);
        box.obj.position.copy(box.initPos);
        box.obj.scale.copy(box.initScale);
      });
    }

    if (2 < t && t < 7) {
      artBoxes.current.forEach((box, i) => {
        let localTime = easeInOutCubic(
          THREE.MathUtils.clamp(t - box.timeMod - 2, 0, transitionTime),
          0,
          1,
          transitionTime
        );

        box.obj.position.lerpVectors(box.initPos, box.targetPos, localTime);
        box.obj.scale.lerpVectors(box.initScale, box.targetScale, localTime);
      });
    }

    if (t > 7) {
      artBoxes.current.forEach((box, i) => {
        let localTime = easeInOutCubic(
          THREE.MathUtils.clamp(t - box.timeMod - 7, 0, transitionTime),
          0,
          1,
          transitionTime
        );
        box.obj.position.lerpVectors(box.targetPos, box.finalPos, localTime);
        box.obj.rotation.y = THREE.MathUtils.lerp(0, box.finalRot, localTime);
      });
    }

    if (t <= 7) {
      let camTime = easeInOutCubic(t, 0, 1, 7);
      state.camera.position.lerpVectors(cameraStartPos, cameraMidPos, camTime);
      state.camera.lookAt(group.current.position);
    }
    if (t > 7) {
      let camTime = easeInQuad(t - 7, 0, 1, 3);
      state.camera.position.lerpVectors(cameraMidPos, cameraEndPos, camTime);
    }
  });

  return (
    <group ref={group}>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
    </group>
  );
}
