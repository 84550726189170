import * as THREE from "three";
import React, { useRef } from "react";
import { useThree, useFrame } from "react-three-fiber";
import XText from "../components/XText";
import { MathUtils } from "three";

const { degToRad } = MathUtils;

export default function Scene050_NotAlone({ data, sceneTime }) {
  const state = useThree();
  const group = useRef();
  const mesh = useRef();

  useFrame(() => {
    const { isActive, time, progress } = sceneTime;

    group.current.visible = isActive;
    if (!isActive) {
      return;
    }

    mesh.current.rotation.y = progress * degToRad(-360) - degToRad(90);

    state.camera.position.set(0, 0, 5);
    state.camera.lookAt(new THREE.Vector3());
  });

  return (
    <group ref={group}>
      <mesh ref={mesh}>
        <XText>Scene050_NotAlone</XText>
      </mesh>
    </group>
  );
}
