import ReactDOM from "react-dom";
import React, { Suspense, useState, useEffect } from "react";
import { FormatScript } from "./script/ScriptFormatter";
import gamerDataDefault from "./data/Donyea_data.json";
import spinner from "./spinner.gif";
import App from "./App";

//import { LoadAssets } from "./AssetLoader";

const DEBUG_QUERY = false;

let gamerData = gamerDataDefault;
let dataValidated = true;

// Check query param for custom json
const params = new URLSearchParams(window.location.search);
if (params.has("gamerData")) {
  let queryData = params.get("gamerData");
  if (queryData != null && queryData != "default") {
    try {
      gamerData = JSON.parse(queryData);
    } catch (e) {
      const t = document.createTextNode("");
      document.body.appendChild(t);
      t.textContent = "[ JSON DATA ERROR ] ";
      dataValidated = false;
    }
  }
}

let data = {};

if (dataValidated) {
  data = {
    user: gamerData,
    script: FormatScript(gamerData),
    assets: {
      textures: {},
    },
  };
}

// Use to debug incoming query data ----->
const debugQuery = () => {
  const t = document.createTextNode("");
  document.body.appendChild(t);
  t.textContent = JSON.stringify(gamerData);
};

if (DEBUG_QUERY) debugQuery();

const LoadApp = () => {
  // original vanilla three.js asset loader ----->
  // const [started, setStarted] = useState(false);
  // useEffect(() => {
  //   LoadAssets(sceneData, setStarted);
  // }, []);
  //return started && <App sceneData={sceneData} />;};
  // --------- Pls save //

  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img src={spinner} />
        </div>
      }
    >
      {console.log("App Data: ", data)}
      <App data={data} />
    </Suspense>
  );
};

ReactDOM.render(<LoadApp />, document.getElementById("root"));
