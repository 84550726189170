import * as THREE from "three";
import React, { useRef, useMemo, useEffect } from "react";
import { useThree, useFrame } from "react-three-fiber";
import { getRandomColorFromPalette, Colors } from "../Colors";
import OutlinedPlane from "../components/outline/OutlinePlane";
import { Group } from "three";

const _object = new THREE.Object3D();
const _color = new THREE.Color();

// User Inputs
const countX = 9;
const countY = 6;
const countZ = 3;
const maxCloneCount = countX * countY * countZ;

const planeSize = 0.5;
const spacingRatio = 0.75;
const spacing = planeSize * spacingRatio;
const stepSize = planeSize + spacing;
const perStep = new THREE.Vector3(stepSize, stepSize, -stepSize * 4);
const randomPosRad = spacing;
const counts = new THREE.Vector3(countX, countY, countZ);
const dimensions = new THREE.Vector3().copy(perStep).multiply(counts);

// Center the Cloner on XY
const offset = new THREE.Vector3(-dimensions.x * 0.5, -dimensions.y * 0.5, 0.0);

// Build Constants
const colors = new Array(maxCloneCount)
  .fill()
  .map(() => getRandomColorFromPalette(Colors));

const randomBetween = (min = -1.0, max = 1.0) => {
  return THREE.MathUtils.lerp(min, max, Math.random());
};

const randomVector = (min = -1.0, max = 1.0) => {
  return new THREE.Vector3(
    randomBetween(min, max),
    randomBetween(min, max),
    randomBetween(min, max)
  );
};

export const createGridPoints = ({
  xCount = 5,
  yCount = 5,
  zCount = 5,
  perStep = new THREE.Vector3(1.0, 1.0, 1.0),
  center = new THREE.Vector3(0, 0, 0), // Set to -1 or 1 to grow from that side.
} = {}) => {
  let points = [];

  let count = xCount * yCount * zCount;
  if (!count) return points;

  console.log("perStep: ", perStep);

  let halfSize = new THREE.Vector3(xCount - 1, yCount - 1, zCount - 1)
    .multiply(perStep)
    .multiplyScalar(0.5);

  // Center, then offset to grow from a particular "center point"
  let offset = new THREE.Vector3(
    -halfSize.x + center.x * halfSize.x,
    -halfSize.y + center.y * halfSize.y,
    -halfSize.z + center.z * halfSize.z
  );

  for (let z = 0; z < zCount; z++) {
    for (let y = 0; y < yCount; y++) {
      for (let x = 0; x < xCount; x++) {
        points.push(new THREE.Vector3(x, y, z).multiply(perStep).add(offset));
      }
    }
  }

  return points;
};

export default function Scene020_TotalGames({ data, sceneTime }) {
  const state = useThree();
  const group = useRef();

  const gridPoints = createGridPoints({
    xCount: 10,
    yCount: 10,
    zCount: 1,
    perStep: new THREE.Vector3(1, 1, 3),
    center: new THREE.Vector3(0, 0, -1),
  });

  const randomizedGridPoints = gridPoints.map((point) => {
    return point.clone().add(randomVector().multiplyScalar(0.5));
  });

  const magnitude = new THREE.Vector3(1, 1, 0);
  const randomizedEndPoints = randomizedGridPoints.map((point) =>
    point.clone().add(randomVector().multiply(magnitude))
  );

  useFrame(() => {
    const { isActive, time, progress } = sceneTime;

    group.current.visible = isActive;
    if (!isActive) {
      return;
    }

    for (let i = 0; i < group.current.children.length; i++) {
      group.current.children[i].position.lerpVectors(
        randomizedGridPoints[i],
        randomizedEndPoints[i],
        1.0 - progress
      );
    }

    // Update Camera
    state.camera.lookAt(state.scene.position);
    state.camera.position.set(0, 0, 0.1 + time);
  }, []);

  return (
    <group ref={group}>
      {gridPoints.map((gridPoint, id) => {
        return (
          <OutlinedPlane
            key={id}
            width={0.8}
            height={0.8}
            position={gridPoint}
          />
        );
      })}
    </group>
  );
}
