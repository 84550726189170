import * as THREE from "three";
import React, { useMemo, useEffect } from "react";
import { useThree, useFrame, extend, useUpdate } from "react-three-fiber";
import { Line2 } from "three/examples/jsm/lines/Line2.js";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry.js";
import { Colors, gradientColorsArray, sampleHexGradient } from "../../Colors";
import { Plane } from "drei";
extend({ Line2, LineMaterial, LineGeometry });

const lerp = THREE.MathUtils.lerp;

const createRectangleArray = ({
  width = 1.0,
  height = undefined,
  segments = 1,
} = {}) => {
  height = height !== undefined ? height : width;
  segments = Math.max(1, segments);

  const halfWidth = width * 0.5;
  const halfHeight = height * 0.5;

  // Corners, Clockwise from LowerLeft
  const LL = [-halfWidth, -halfHeight, 0];
  const UL = [-halfWidth, halfHeight, 0];
  const UR = [halfWidth, halfHeight, 0];
  const LR = [halfWidth, -halfHeight, 0];

  const sides = [
    [LL, UL],
    [UL, UR],
    [UR, LR],
    [LR, LL],
  ];

  let points = [];

  sides.forEach((side, s) => {
    const [a, b] = side;

    const pointsPerSegment = segments * 2 - 1;
    for (let i = 0; i < pointsPerSegment; i++) {
      let t = i / pointsPerSegment;

      let point = [
        lerp(a[0], b[0], t),
        lerp(a[1], b[1], t),
        lerp(a[2], b[2], t),
      ];

      points.push(point);
    }
  });

  // Close
  points.push(points[0]);

  return points;
};

export const PlaneOutline = ({
  width = 1,
  height = undefined,
  segments,
  scale,
  position,
  ...props
} = {}) => {
  const { camera, scene } = useThree();

  const points = {
    frontFace: createRectangleArray({
      width,
      height,
      segments,
    }),
  };

  const lineGeo = {};
  const box = {};

  var color = new THREE.Color();

  const matLine = new LineMaterial({
    color: 0xffffff,
    linewidth: 5, // in pixels
    vertexColors: true,
  });

  const makeLineGeo = (points) => {
    var positions = [];
    var colors = [];

    for (var i = 0; i < points.length; i++) {
      positions.push(...points[i]);

      // Ensure last color matches first.
      color.set(
        sampleHexGradient(i / (points.length - 1), gradientColorsArray)
      );
      colors.push(color.r, color.g, color.b);
    }

    var lineGeometry = new LineGeometry();
    lineGeometry.setPositions(positions);
    lineGeometry.setColors(colors);
    return lineGeometry;
  };

  useEffect(() => {
    lineGeo.frontFace = makeLineGeo(points.frontFace);
    box.frontFace = new Line2(lineGeo.frontFace, matLine);
    grp.current.add(box.frontFace);
  }, []);

  const grp = useUpdate(() => {
    matLine.resolution.set(window.innerWidth, window.innerHeight); // resolution of the viewport
    matLine.linewidth =
      5 - camera.position.distanceTo(grp.current.position) / 100;
  }, [position, camera.position]);

  return <group ref={grp} position={position} scale={scale}></group>;
};

const OutlinedPlane = ({ width, height, ...props }) => {
  return (
    <group {...props}>
      <PlaneOutline width={width} height={height} segments={3} />
      <Plane args={[width, height]}>
        <meshBasicMaterial attach="material" color="black" />
      </Plane>
    </group>
  );
};

export default OutlinedPlane;
