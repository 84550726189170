import React from "react";
import useAnimationFrame from "../../hooks/useAnimationFrame";
import { HudData } from "../../Project";

const Hud = () => {
  const [time, setTime] = React.useState(0);

  useAnimationFrame((delta) => {
    setTime((prevTime) => prevTime + delta);
  });

  return (
    <div
      style={{
        color: "white",
        position: "absolute",
        bottom: 0,
        width: "100%",
        fontSize: "25px",
        padding: "10px 40px",
      }}
    >
      {HudData.time.toFixed(2)} / {HudData.duration.toFixed(2)} [{HudData.speed}
      x] | "{HudData.scene}" ({HudData.number}/{HudData.count})
    </div>
  );
};

export default Hud;
