import * as THREE from "three";
import React, { useRef, useMemo, useEffect } from "react";
import { Colors } from "../Colors";
import { Text, preloadFont } from "troika-three-text";

let isLoaded = false;
export { isLoaded };

preloadFont(
  {
    font: "/fonts/seguibl.woff",
    characters: "abcdefghijklmnopqrstuvwxyz!,.?",
  },
  () => {
    console.log("Troika: Font preload complete.");
    isLoaded = true;
  }
);

export default function XText({
  color = Colors.darkGreen,
  fontSize = 0.6,
  font = "/fonts/seguibl.woff",
  anchorX = "center",
  children,
  ...rest
}) {
  const newText = useRef(new Text());
  const group = useRef();

  useMemo(() => {
    newText.current.text = children;
    newText.current.font = font;
    newText.current.fontSize = fontSize;
    newText.current.anchorX = anchorX;
    newText.current.anchorY = "middle";
    newText.current.color = color;
    newText.current.sync();
  }, []);

  useEffect(() => {
    group.current.add(newText.current);
  });

  return <group ref={group} {...rest} />;
}
