import * as THREE from "three";

export const Primary = {
  lightGreen: 0x9bf00b,
  green: 0x107c10,
  darkGreen: 0x054b16,
};

export const Secondary = {
  lightGray: 0xe6e6e6,
  gray: 0x505050,
  white: 0xffffff,
};

export const Bright = {
  ...Primary,
  ...Secondary,
};

export const Colors = {
  ...Bright,
  ...Secondary,
  black: 0x00000,
};

export const gradientColorsArray = [
  0x008b1c,
  0x8f4c68,
  0x7a4e9c,
  0x3f63a1,
  0x99f007,
  0xf2f9e9,
  0x108927,
  0xf2f9e9,
  0x9be70d,
  0x408293,
  0x6b559c,
  0x8c4d68,
  0x008b1c,
];

const magenta = 0xff00ff;
const ERROR = magenta;

export const sampleHexGradient = (t = 0, colors = [ERROR]) => {
  // Keep t in bounds, allows for looping animation.
  t = t % 1.0;

  const count = colors.length;

  // Loop the Knots
  const knots = [...colors, colors[0]];

  const realId = t * count;

  const a = Math.floor(realId);
  const b = Math.ceil(realId);

  const colorA = new THREE.Color(knots[a]);
  const colorB = new THREE.Color(knots[b]);

  const alpha = realId % 1.0;

  return colorA.lerp(colorB, alpha);
};

export const getRandomColorFromPalette = (palette) => {
  const arr = Object.values(palette);

  return arr[Math.floor(Math.random() * arr.length) % arr.length];
};
