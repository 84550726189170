// master script json
import script from "../script/script.json";

export function FormatScript(data) {
  // replace dynamic keys in script with user data
  Object.keys(script).forEach((card) => {
    script[card] = script[card].replace("%gamertag%", data["gamertag"].toUpperCase());
    script[card] = script[card].replace("%firstplayed%", data["firstplayed"]["game"].toUpperCase());
    script[card] = script[card].replace("%lastplayed%", data["lastplayed"]["game"].toUpperCase());
    script[card] = script[card].replace("%gamerscore%", data["gamerscore"]);
  });
  return script;
}
